<nav class="navbar box-shadow app-header" [class.fixed-top]="settings.theme.navbarIsFixed" [class.logo-visible]="showInfoContent">
    <div class="d-flex flex-column w-100">
        <div id="info-content" class="panel-collapse collapse">
            <a class="close" data-toggle="collapse" href="#info-content" (click)="showInfoContent = !showInfoContent" [class.show]="showInfoContent">
                <span aria-hidden="true">&times;</span>
            </a>
        </div>
        <div class="d-flex justify-content-start align-items-center top-navbar">                  
            
            <a class="logo" routerLink="/" (click)="closeSubMenus()">
                <img src="assets/img/logo/ue-logo-mobile.svg" class="logo-mobile" height="80%">
            </a>

            <span class="navbar-text menu-icon transition ml-3 mr-3" [ngClass]="{'open': settings.theme.showMenu}"
                (click)="settings.theme.showMenu = !settings.theme.showMenu">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </span>

            <div class="top-rigth-icons ml-auto ng-tns-c157-35">
                <a (click)="logout()" class="dropdown-item mb-1"><i class="fa fa-sign-out mr-2"></i></a>
            </div>

        </div>

        <div class="horizontal-menu-outer" *ngIf="settings.theme.menu == 'horizontal'">                   
            <app-horizontal-menu *ngIf="showHorizontalMenu"  [menuItems]="menuItems"></app-horizontal-menu>        
            <app-vertical-menu *ngIf="!showHorizontalMenu"  [menuItems]="menuItems"></app-vertical-menu>
        </div>

    </div>
</nav>