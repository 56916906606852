import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';

export const routes: Routes = [
  {
    path: '', 
    component: PagesComponent,
    children:[
      { path: '', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Crear Despliegue' }  },          
      { path: 'history', loadChildren: () => import('./pages/history/history.module').then(m => m.HistoryModule), data: { breadcrumb: 'Histórico' }  },          
      { path: 'predictive', loadChildren: () => import('./pages/predictive/predictive.module').then(m => m.PredictiveModule), data: { breadcrumb: 'Carga Predictiva' }  },          
    ]
  },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: '**', component: NotFoundComponent }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
   preloadingStrategy: PreloadAllModules,  // <- comment this line for enable lazy load
  // useHash: true
});